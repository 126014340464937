/* tslint:disable */
/* eslint-disable */
/**
 * Dataland Community Manager API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../../../../../../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../../../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../../../../../../base';
// @ts-ignore
import type { AccessStatus } from '../../../../../../org/dataland/datalandfrontend/openApiClient/communitymanager/model';
// @ts-ignore
import type { AggregatedDataRequest } from '../../../../../../org/dataland/datalandfrontend/openApiClient/communitymanager/model';
// @ts-ignore
import type { BulkDataRequest } from '../../../../../../org/dataland/datalandfrontend/openApiClient/communitymanager/model';
// @ts-ignore
import type { BulkDataRequestResponse } from '../../../../../../org/dataland/datalandfrontend/openApiClient/communitymanager/model';
// @ts-ignore
import type { ErrorResponse } from '../../../../../../org/dataland/datalandfrontend/openApiClient/communitymanager/model';
// @ts-ignore
import type { ExtendedStoredDataRequest } from '../../../../../../org/dataland/datalandfrontend/openApiClient/communitymanager/model';
// @ts-ignore
import type { RequestPriority } from '../../../../../../org/dataland/datalandfrontend/openApiClient/communitymanager/model';
// @ts-ignore
import type { RequestStatus } from '../../../../../../org/dataland/datalandfrontend/openApiClient/communitymanager/model';
// @ts-ignore
import type { SingleDataRequest } from '../../../../../../org/dataland/datalandfrontend/openApiClient/communitymanager/model';
// @ts-ignore
import type { SingleDataRequestResponse } from '../../../../../../org/dataland/datalandfrontend/openApiClient/communitymanager/model';
// @ts-ignore
import type { StoredDataRequest } from '../../../../../../org/dataland/datalandfrontend/openApiClient/communitymanager/model';
/**
 * RequestControllerApi - axios parameter creator
 * @export
 */
export const RequestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Gets all data requests that match the given filters, while aggregating userIDs.
         * @summary Get aggregated data requests.
         * @param {string} [identifierValue] 
         * @param {Set<GetAggregatedDataRequestsDataTypesEnum>} [dataTypes] 
         * @param {string} [reportingPeriod] 
         * @param {RequestStatus} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAggregatedDataRequests: async (identifierValue?: string, dataTypes?: Set<GetAggregatedDataRequestsDataTypesEnum>, reportingPeriod?: string, status?: RequestStatus, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/requests/aggregated`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication default-bearer-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication default-oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "default-oauth", [], configuration)

            if (identifierValue !== undefined) {
                localVarQueryParameter['identifierValue'] = identifierValue;
            }

            if (dataTypes) {
                localVarQueryParameter['dataTypes'] = Array.from(dataTypes);
            }

            if (reportingPeriod !== undefined) {
                localVarQueryParameter['reportingPeriod'] = reportingPeriod;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets the stored data request corresponding to the provided data request ID.
         * @summary Gets a stored data request for a given ID.
         * @param {string} dataRequestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataRequestById: async (dataRequestId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dataRequestId' is not null or undefined
            assertParamExists('getDataRequestById', 'dataRequestId', dataRequestId)
            const localVarPath = `/requests/{dataRequestId}`
                .replace(`{${"dataRequestId"}}`, encodeURIComponent(String(dataRequestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication default-bearer-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication default-oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "default-oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets all the stored data request based on filters.
         * @summary Get all stored data requests based on filters.
         * @param {Set<GetDataRequestsDataTypeEnum>} [dataType] 
         * @param {string} [userId] 
         * @param {string} [emailAddress] 
         * @param {string} [adminComment] 
         * @param {Set<RequestStatus>} [requestStatus] 
         * @param {Set<AccessStatus>} [accessStatus] 
         * @param {Set<RequestPriority>} [requestPriority] 
         * @param {string} [reportingPeriod] 
         * @param {string} [datalandCompanyId] 
         * @param {number} [chunkSize] 
         * @param {number} [chunkIndex] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataRequests: async (dataType?: Set<GetDataRequestsDataTypeEnum>, userId?: string, emailAddress?: string, adminComment?: string, requestStatus?: Set<RequestStatus>, accessStatus?: Set<AccessStatus>, requestPriority?: Set<RequestPriority>, reportingPeriod?: string, datalandCompanyId?: string, chunkSize?: number, chunkIndex?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/requests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication default-bearer-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication default-oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "default-oauth", [], configuration)

            if (dataType) {
                localVarQueryParameter['dataType'] = Array.from(dataType);
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (emailAddress !== undefined) {
                localVarQueryParameter['emailAddress'] = emailAddress;
            }

            if (adminComment !== undefined) {
                localVarQueryParameter['adminComment'] = adminComment;
            }

            if (requestStatus) {
                localVarQueryParameter['requestStatus'] = Array.from(requestStatus);
            }

            if (accessStatus) {
                localVarQueryParameter['accessStatus'] = Array.from(accessStatus);
            }

            if (requestPriority) {
                localVarQueryParameter['requestPriority'] = Array.from(requestPriority);
            }

            if (reportingPeriod !== undefined) {
                localVarQueryParameter['reportingPeriod'] = reportingPeriod;
            }

            if (datalandCompanyId !== undefined) {
                localVarQueryParameter['datalandCompanyId'] = datalandCompanyId;
            }

            if (chunkSize !== undefined) {
                localVarQueryParameter['chunkSize'] = chunkSize;
            }

            if (chunkIndex !== undefined) {
                localVarQueryParameter['chunkIndex'] = chunkIndex;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets all the stored data request created by the user who is making the request.
         * @summary Get all stored data requests of the user making the request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataRequestsForRequestingUser: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/requests/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication default-bearer-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication default-oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "default-oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the number of requests based on filters.
         * @summary Get the number of requests based on filters.
         * @param {Set<GetNumberOfRequestsDataTypeEnum>} [dataType] 
         * @param {string} [userId] 
         * @param {string} [emailAddress] 
         * @param {string} [adminComment] 
         * @param {Set<RequestStatus>} [requestStatus] 
         * @param {Set<AccessStatus>} [accessStatus] 
         * @param {Set<RequestPriority>} [requestPriority] 
         * @param {string} [reportingPeriod] 
         * @param {string} [datalandCompanyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNumberOfRequests: async (dataType?: Set<GetNumberOfRequestsDataTypeEnum>, userId?: string, emailAddress?: string, adminComment?: string, requestStatus?: Set<RequestStatus>, accessStatus?: Set<AccessStatus>, requestPriority?: Set<RequestPriority>, reportingPeriod?: string, datalandCompanyId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/requests/numberOfRequests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication default-bearer-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication default-oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "default-oauth", [], configuration)

            if (dataType) {
                localVarQueryParameter['dataType'] = Array.from(dataType);
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (emailAddress !== undefined) {
                localVarQueryParameter['emailAddress'] = emailAddress;
            }

            if (adminComment !== undefined) {
                localVarQueryParameter['adminComment'] = adminComment;
            }

            if (requestStatus) {
                localVarQueryParameter['requestStatus'] = Array.from(requestStatus);
            }

            if (accessStatus) {
                localVarQueryParameter['accessStatus'] = Array.from(accessStatus);
            }

            if (requestPriority) {
                localVarQueryParameter['requestPriority'] = Array.from(requestPriority);
            }

            if (reportingPeriod !== undefined) {
                localVarQueryParameter['reportingPeriod'] = reportingPeriod;
            }

            if (datalandCompanyId !== undefined) {
                localVarQueryParameter['datalandCompanyId'] = datalandCompanyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This head request checks whether the logged-in user has access to dataset.
         * @summary This head request checks whether the logged-in user has access to dataset.
         * @param {string} companyId 
         * @param {string} dataType 
         * @param {string} reportingPeriod 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hasAccessToDataset: async (companyId: string, dataType: string, reportingPeriod: string, userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('hasAccessToDataset', 'companyId', companyId)
            // verify required parameter 'dataType' is not null or undefined
            assertParamExists('hasAccessToDataset', 'dataType', dataType)
            // verify required parameter 'reportingPeriod' is not null or undefined
            assertParamExists('hasAccessToDataset', 'reportingPeriod', reportingPeriod)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('hasAccessToDataset', 'userId', userId)
            const localVarPath = `/requests/dataset-access/{companyId}/{dataType}/{reportingPeriod}/{userId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"dataType"}}`, encodeURIComponent(String(dataType)))
                .replace(`{${"reportingPeriod"}}`, encodeURIComponent(String(reportingPeriod)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'HEAD', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication default-bearer-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication default-oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "default-oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates a data request given the data request id.
         * @summary Updates a data request.
         * @param {string} dataRequestId 
         * @param {RequestStatus} [requestStatus] 
         * @param {AccessStatus} [accessStatus] 
         * @param {Set<string>} [contacts] 
         * @param {string} [message] 
         * @param {RequestPriority} [requestPriority] 
         * @param {string} [adminComment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchDataRequest: async (dataRequestId: string, requestStatus?: RequestStatus, accessStatus?: AccessStatus, contacts?: Set<string>, message?: string, requestPriority?: RequestPriority, adminComment?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dataRequestId' is not null or undefined
            assertParamExists('patchDataRequest', 'dataRequestId', dataRequestId)
            const localVarPath = `/requests/{dataRequestId}/requestStatus`
                .replace(`{${"dataRequestId"}}`, encodeURIComponent(String(dataRequestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication default-bearer-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication default-oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "default-oauth", [], configuration)

            if (requestStatus !== undefined) {
                localVarQueryParameter['requestStatus'] = requestStatus;
            }

            if (accessStatus !== undefined) {
                localVarQueryParameter['accessStatus'] = accessStatus;
            }

            if (contacts) {
                localVarQueryParameter['contacts'] = Array.from(contacts);
            }

            if (message !== undefined) {
                localVarQueryParameter['message'] = message;
            }

            if (requestPriority !== undefined) {
                localVarQueryParameter['requestPriority'] = requestPriority;
            }

            if (adminComment !== undefined) {
                localVarQueryParameter['adminComment'] = adminComment;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * A bulk of data requests for specific frameworks and companies is being sent.
         * @summary Send a bulk request
         * @param {BulkDataRequest} bulkDataRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postBulkDataRequest: async (bulkDataRequest: BulkDataRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bulkDataRequest' is not null or undefined
            assertParamExists('postBulkDataRequest', 'bulkDataRequest', bulkDataRequest)
            const localVarPath = `/requests/bulk`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication default-bearer-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication default-oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "default-oauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bulkDataRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * A single of data requests for specific frameworks and companies is being sent.
         * @summary Send a single request
         * @param {SingleDataRequest} singleDataRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postSingleDataRequest: async (singleDataRequest: SingleDataRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'singleDataRequest' is not null or undefined
            assertParamExists('postSingleDataRequest', 'singleDataRequest', singleDataRequest)
            const localVarPath = `/requests/single`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication default-bearer-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication default-oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "default-oauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(singleDataRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RequestControllerApi - functional programming interface
 * @export
 */
export const RequestControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RequestControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * Gets all data requests that match the given filters, while aggregating userIDs.
         * @summary Get aggregated data requests.
         * @param {string} [identifierValue] 
         * @param {Set<GetAggregatedDataRequestsDataTypesEnum>} [dataTypes] 
         * @param {string} [reportingPeriod] 
         * @param {RequestStatus} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAggregatedDataRequests(identifierValue?: string, dataTypes?: Set<GetAggregatedDataRequestsDataTypesEnum>, reportingPeriod?: string, status?: RequestStatus, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AggregatedDataRequest>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAggregatedDataRequests(identifierValue, dataTypes, reportingPeriod, status, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RequestControllerApi.getAggregatedDataRequests']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Gets the stored data request corresponding to the provided data request ID.
         * @summary Gets a stored data request for a given ID.
         * @param {string} dataRequestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDataRequestById(dataRequestId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StoredDataRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDataRequestById(dataRequestId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RequestControllerApi.getDataRequestById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Gets all the stored data request based on filters.
         * @summary Get all stored data requests based on filters.
         * @param {Set<GetDataRequestsDataTypeEnum>} [dataType] 
         * @param {string} [userId] 
         * @param {string} [emailAddress] 
         * @param {string} [adminComment] 
         * @param {Set<RequestStatus>} [requestStatus] 
         * @param {Set<AccessStatus>} [accessStatus] 
         * @param {Set<RequestPriority>} [requestPriority] 
         * @param {string} [reportingPeriod] 
         * @param {string} [datalandCompanyId] 
         * @param {number} [chunkSize] 
         * @param {number} [chunkIndex] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDataRequests(dataType?: Set<GetDataRequestsDataTypeEnum>, userId?: string, emailAddress?: string, adminComment?: string, requestStatus?: Set<RequestStatus>, accessStatus?: Set<AccessStatus>, requestPriority?: Set<RequestPriority>, reportingPeriod?: string, datalandCompanyId?: string, chunkSize?: number, chunkIndex?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ExtendedStoredDataRequest>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDataRequests(dataType, userId, emailAddress, adminComment, requestStatus, accessStatus, requestPriority, reportingPeriod, datalandCompanyId, chunkSize, chunkIndex, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RequestControllerApi.getDataRequests']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Gets all the stored data request created by the user who is making the request.
         * @summary Get all stored data requests of the user making the request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDataRequestsForRequestingUser(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ExtendedStoredDataRequest>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDataRequestsForRequestingUser(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RequestControllerApi.getDataRequestsForRequestingUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get the number of requests based on filters.
         * @summary Get the number of requests based on filters.
         * @param {Set<GetNumberOfRequestsDataTypeEnum>} [dataType] 
         * @param {string} [userId] 
         * @param {string} [emailAddress] 
         * @param {string} [adminComment] 
         * @param {Set<RequestStatus>} [requestStatus] 
         * @param {Set<AccessStatus>} [accessStatus] 
         * @param {Set<RequestPriority>} [requestPriority] 
         * @param {string} [reportingPeriod] 
         * @param {string} [datalandCompanyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNumberOfRequests(dataType?: Set<GetNumberOfRequestsDataTypeEnum>, userId?: string, emailAddress?: string, adminComment?: string, requestStatus?: Set<RequestStatus>, accessStatus?: Set<AccessStatus>, requestPriority?: Set<RequestPriority>, reportingPeriod?: string, datalandCompanyId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNumberOfRequests(dataType, userId, emailAddress, adminComment, requestStatus, accessStatus, requestPriority, reportingPeriod, datalandCompanyId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RequestControllerApi.getNumberOfRequests']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * This head request checks whether the logged-in user has access to dataset.
         * @summary This head request checks whether the logged-in user has access to dataset.
         * @param {string} companyId 
         * @param {string} dataType 
         * @param {string} reportingPeriod 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async hasAccessToDataset(companyId: string, dataType: string, reportingPeriod: string, userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.hasAccessToDataset(companyId, dataType, reportingPeriod, userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RequestControllerApi.hasAccessToDataset']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates a data request given the data request id.
         * @summary Updates a data request.
         * @param {string} dataRequestId 
         * @param {RequestStatus} [requestStatus] 
         * @param {AccessStatus} [accessStatus] 
         * @param {Set<string>} [contacts] 
         * @param {string} [message] 
         * @param {RequestPriority} [requestPriority] 
         * @param {string} [adminComment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchDataRequest(dataRequestId: string, requestStatus?: RequestStatus, accessStatus?: AccessStatus, contacts?: Set<string>, message?: string, requestPriority?: RequestPriority, adminComment?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StoredDataRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchDataRequest(dataRequestId, requestStatus, accessStatus, contacts, message, requestPriority, adminComment, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RequestControllerApi.patchDataRequest']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * A bulk of data requests for specific frameworks and companies is being sent.
         * @summary Send a bulk request
         * @param {BulkDataRequest} bulkDataRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postBulkDataRequest(bulkDataRequest: BulkDataRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BulkDataRequestResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postBulkDataRequest(bulkDataRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RequestControllerApi.postBulkDataRequest']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * A single of data requests for specific frameworks and companies is being sent.
         * @summary Send a single request
         * @param {SingleDataRequest} singleDataRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postSingleDataRequest(singleDataRequest: SingleDataRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SingleDataRequestResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postSingleDataRequest(singleDataRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RequestControllerApi.postSingleDataRequest']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * RequestControllerApi - factory interface
 * @export
 */
export const RequestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RequestControllerApiFp(configuration)
    return {
        /**
         * Gets all data requests that match the given filters, while aggregating userIDs.
         * @summary Get aggregated data requests.
         * @param {string} [identifierValue] 
         * @param {Set<GetAggregatedDataRequestsDataTypesEnum>} [dataTypes] 
         * @param {string} [reportingPeriod] 
         * @param {RequestStatus} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAggregatedDataRequests(identifierValue?: string, dataTypes?: Set<GetAggregatedDataRequestsDataTypesEnum>, reportingPeriod?: string, status?: RequestStatus, options?: any): AxiosPromise<Array<AggregatedDataRequest>> {
            return localVarFp.getAggregatedDataRequests(identifierValue, dataTypes, reportingPeriod, status, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets the stored data request corresponding to the provided data request ID.
         * @summary Gets a stored data request for a given ID.
         * @param {string} dataRequestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataRequestById(dataRequestId: string, options?: any): AxiosPromise<StoredDataRequest> {
            return localVarFp.getDataRequestById(dataRequestId, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets all the stored data request based on filters.
         * @summary Get all stored data requests based on filters.
         * @param {Set<GetDataRequestsDataTypeEnum>} [dataType] 
         * @param {string} [userId] 
         * @param {string} [emailAddress] 
         * @param {string} [adminComment] 
         * @param {Set<RequestStatus>} [requestStatus] 
         * @param {Set<AccessStatus>} [accessStatus] 
         * @param {Set<RequestPriority>} [requestPriority] 
         * @param {string} [reportingPeriod] 
         * @param {string} [datalandCompanyId] 
         * @param {number} [chunkSize] 
         * @param {number} [chunkIndex] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataRequests(dataType?: Set<GetDataRequestsDataTypeEnum>, userId?: string, emailAddress?: string, adminComment?: string, requestStatus?: Set<RequestStatus>, accessStatus?: Set<AccessStatus>, requestPriority?: Set<RequestPriority>, reportingPeriod?: string, datalandCompanyId?: string, chunkSize?: number, chunkIndex?: number, options?: any): AxiosPromise<Array<ExtendedStoredDataRequest>> {
            return localVarFp.getDataRequests(dataType, userId, emailAddress, adminComment, requestStatus, accessStatus, requestPriority, reportingPeriod, datalandCompanyId, chunkSize, chunkIndex, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets all the stored data request created by the user who is making the request.
         * @summary Get all stored data requests of the user making the request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataRequestsForRequestingUser(options?: any): AxiosPromise<Array<ExtendedStoredDataRequest>> {
            return localVarFp.getDataRequestsForRequestingUser(options).then((request) => request(axios, basePath));
        },
        /**
         * Get the number of requests based on filters.
         * @summary Get the number of requests based on filters.
         * @param {Set<GetNumberOfRequestsDataTypeEnum>} [dataType] 
         * @param {string} [userId] 
         * @param {string} [emailAddress] 
         * @param {string} [adminComment] 
         * @param {Set<RequestStatus>} [requestStatus] 
         * @param {Set<AccessStatus>} [accessStatus] 
         * @param {Set<RequestPriority>} [requestPriority] 
         * @param {string} [reportingPeriod] 
         * @param {string} [datalandCompanyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNumberOfRequests(dataType?: Set<GetNumberOfRequestsDataTypeEnum>, userId?: string, emailAddress?: string, adminComment?: string, requestStatus?: Set<RequestStatus>, accessStatus?: Set<AccessStatus>, requestPriority?: Set<RequestPriority>, reportingPeriod?: string, datalandCompanyId?: string, options?: any): AxiosPromise<number> {
            return localVarFp.getNumberOfRequests(dataType, userId, emailAddress, adminComment, requestStatus, accessStatus, requestPriority, reportingPeriod, datalandCompanyId, options).then((request) => request(axios, basePath));
        },
        /**
         * This head request checks whether the logged-in user has access to dataset.
         * @summary This head request checks whether the logged-in user has access to dataset.
         * @param {string} companyId 
         * @param {string} dataType 
         * @param {string} reportingPeriod 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hasAccessToDataset(companyId: string, dataType: string, reportingPeriod: string, userId: string, options?: any): AxiosPromise<void> {
            return localVarFp.hasAccessToDataset(companyId, dataType, reportingPeriod, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates a data request given the data request id.
         * @summary Updates a data request.
         * @param {string} dataRequestId 
         * @param {RequestStatus} [requestStatus] 
         * @param {AccessStatus} [accessStatus] 
         * @param {Set<string>} [contacts] 
         * @param {string} [message] 
         * @param {RequestPriority} [requestPriority] 
         * @param {string} [adminComment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchDataRequest(dataRequestId: string, requestStatus?: RequestStatus, accessStatus?: AccessStatus, contacts?: Set<string>, message?: string, requestPriority?: RequestPriority, adminComment?: string, options?: any): AxiosPromise<StoredDataRequest> {
            return localVarFp.patchDataRequest(dataRequestId, requestStatus, accessStatus, contacts, message, requestPriority, adminComment, options).then((request) => request(axios, basePath));
        },
        /**
         * A bulk of data requests for specific frameworks and companies is being sent.
         * @summary Send a bulk request
         * @param {BulkDataRequest} bulkDataRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postBulkDataRequest(bulkDataRequest: BulkDataRequest, options?: any): AxiosPromise<BulkDataRequestResponse> {
            return localVarFp.postBulkDataRequest(bulkDataRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * A single of data requests for specific frameworks and companies is being sent.
         * @summary Send a single request
         * @param {SingleDataRequest} singleDataRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postSingleDataRequest(singleDataRequest: SingleDataRequest, options?: any): AxiosPromise<SingleDataRequestResponse> {
            return localVarFp.postSingleDataRequest(singleDataRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RequestControllerApi - interface
 * @export
 * @interface RequestControllerApi
 */
export interface RequestControllerApiInterface {
    /**
     * Gets all data requests that match the given filters, while aggregating userIDs.
     * @summary Get aggregated data requests.
     * @param {string} [identifierValue] 
     * @param {Set<GetAggregatedDataRequestsDataTypesEnum>} [dataTypes] 
     * @param {string} [reportingPeriod] 
     * @param {RequestStatus} [status] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RequestControllerApiInterface
     */
    getAggregatedDataRequests(identifierValue?: string, dataTypes?: Set<GetAggregatedDataRequestsDataTypesEnum>, reportingPeriod?: string, status?: RequestStatus, options?: RawAxiosRequestConfig): AxiosPromise<Array<AggregatedDataRequest>>;

    /**
     * Gets the stored data request corresponding to the provided data request ID.
     * @summary Gets a stored data request for a given ID.
     * @param {string} dataRequestId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RequestControllerApiInterface
     */
    getDataRequestById(dataRequestId: string, options?: RawAxiosRequestConfig): AxiosPromise<StoredDataRequest>;

    /**
     * Gets all the stored data request based on filters.
     * @summary Get all stored data requests based on filters.
     * @param {Set<GetDataRequestsDataTypeEnum>} [dataType] 
     * @param {string} [userId] 
     * @param {string} [emailAddress] 
     * @param {string} [adminComment] 
     * @param {Set<RequestStatus>} [requestStatus] 
     * @param {Set<AccessStatus>} [accessStatus] 
     * @param {Set<RequestPriority>} [requestPriority] 
     * @param {string} [reportingPeriod] 
     * @param {string} [datalandCompanyId] 
     * @param {number} [chunkSize] 
     * @param {number} [chunkIndex] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RequestControllerApiInterface
     */
    getDataRequests(dataType?: Set<GetDataRequestsDataTypeEnum>, userId?: string, emailAddress?: string, adminComment?: string, requestStatus?: Set<RequestStatus>, accessStatus?: Set<AccessStatus>, requestPriority?: Set<RequestPriority>, reportingPeriod?: string, datalandCompanyId?: string, chunkSize?: number, chunkIndex?: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<ExtendedStoredDataRequest>>;

    /**
     * Gets all the stored data request created by the user who is making the request.
     * @summary Get all stored data requests of the user making the request.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RequestControllerApiInterface
     */
    getDataRequestsForRequestingUser(options?: RawAxiosRequestConfig): AxiosPromise<Array<ExtendedStoredDataRequest>>;

    /**
     * Get the number of requests based on filters.
     * @summary Get the number of requests based on filters.
     * @param {Set<GetNumberOfRequestsDataTypeEnum>} [dataType] 
     * @param {string} [userId] 
     * @param {string} [emailAddress] 
     * @param {string} [adminComment] 
     * @param {Set<RequestStatus>} [requestStatus] 
     * @param {Set<AccessStatus>} [accessStatus] 
     * @param {Set<RequestPriority>} [requestPriority] 
     * @param {string} [reportingPeriod] 
     * @param {string} [datalandCompanyId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RequestControllerApiInterface
     */
    getNumberOfRequests(dataType?: Set<GetNumberOfRequestsDataTypeEnum>, userId?: string, emailAddress?: string, adminComment?: string, requestStatus?: Set<RequestStatus>, accessStatus?: Set<AccessStatus>, requestPriority?: Set<RequestPriority>, reportingPeriod?: string, datalandCompanyId?: string, options?: RawAxiosRequestConfig): AxiosPromise<number>;

    /**
     * This head request checks whether the logged-in user has access to dataset.
     * @summary This head request checks whether the logged-in user has access to dataset.
     * @param {string} companyId 
     * @param {string} dataType 
     * @param {string} reportingPeriod 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RequestControllerApiInterface
     */
    hasAccessToDataset(companyId: string, dataType: string, reportingPeriod: string, userId: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * Updates a data request given the data request id.
     * @summary Updates a data request.
     * @param {string} dataRequestId 
     * @param {RequestStatus} [requestStatus] 
     * @param {AccessStatus} [accessStatus] 
     * @param {Set<string>} [contacts] 
     * @param {string} [message] 
     * @param {RequestPriority} [requestPriority] 
     * @param {string} [adminComment] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RequestControllerApiInterface
     */
    patchDataRequest(dataRequestId: string, requestStatus?: RequestStatus, accessStatus?: AccessStatus, contacts?: Set<string>, message?: string, requestPriority?: RequestPriority, adminComment?: string, options?: RawAxiosRequestConfig): AxiosPromise<StoredDataRequest>;

    /**
     * A bulk of data requests for specific frameworks and companies is being sent.
     * @summary Send a bulk request
     * @param {BulkDataRequest} bulkDataRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RequestControllerApiInterface
     */
    postBulkDataRequest(bulkDataRequest: BulkDataRequest, options?: RawAxiosRequestConfig): AxiosPromise<BulkDataRequestResponse>;

    /**
     * A single of data requests for specific frameworks and companies is being sent.
     * @summary Send a single request
     * @param {SingleDataRequest} singleDataRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RequestControllerApiInterface
     */
    postSingleDataRequest(singleDataRequest: SingleDataRequest, options?: RawAxiosRequestConfig): AxiosPromise<SingleDataRequestResponse>;

}

/**
 * RequestControllerApi - object-oriented interface
 * @export
 * @class RequestControllerApi
 * @extends {BaseAPI}
 */
export class RequestControllerApi extends BaseAPI implements RequestControllerApiInterface {
    /**
     * Gets all data requests that match the given filters, while aggregating userIDs.
     * @summary Get aggregated data requests.
     * @param {string} [identifierValue] 
     * @param {Set<GetAggregatedDataRequestsDataTypesEnum>} [dataTypes] 
     * @param {string} [reportingPeriod] 
     * @param {RequestStatus} [status] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RequestControllerApi
     */
    public getAggregatedDataRequests(identifierValue?: string, dataTypes?: Set<GetAggregatedDataRequestsDataTypesEnum>, reportingPeriod?: string, status?: RequestStatus, options?: RawAxiosRequestConfig) {
        return RequestControllerApiFp(this.configuration).getAggregatedDataRequests(identifierValue, dataTypes, reportingPeriod, status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets the stored data request corresponding to the provided data request ID.
     * @summary Gets a stored data request for a given ID.
     * @param {string} dataRequestId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RequestControllerApi
     */
    public getDataRequestById(dataRequestId: string, options?: RawAxiosRequestConfig) {
        return RequestControllerApiFp(this.configuration).getDataRequestById(dataRequestId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets all the stored data request based on filters.
     * @summary Get all stored data requests based on filters.
     * @param {Set<GetDataRequestsDataTypeEnum>} [dataType] 
     * @param {string} [userId] 
     * @param {string} [emailAddress] 
     * @param {string} [adminComment] 
     * @param {Set<RequestStatus>} [requestStatus] 
     * @param {Set<AccessStatus>} [accessStatus] 
     * @param {Set<RequestPriority>} [requestPriority] 
     * @param {string} [reportingPeriod] 
     * @param {string} [datalandCompanyId] 
     * @param {number} [chunkSize] 
     * @param {number} [chunkIndex] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RequestControllerApi
     */
    public getDataRequests(dataType?: Set<GetDataRequestsDataTypeEnum>, userId?: string, emailAddress?: string, adminComment?: string, requestStatus?: Set<RequestStatus>, accessStatus?: Set<AccessStatus>, requestPriority?: Set<RequestPriority>, reportingPeriod?: string, datalandCompanyId?: string, chunkSize?: number, chunkIndex?: number, options?: RawAxiosRequestConfig) {
        return RequestControllerApiFp(this.configuration).getDataRequests(dataType, userId, emailAddress, adminComment, requestStatus, accessStatus, requestPriority, reportingPeriod, datalandCompanyId, chunkSize, chunkIndex, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets all the stored data request created by the user who is making the request.
     * @summary Get all stored data requests of the user making the request.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RequestControllerApi
     */
    public getDataRequestsForRequestingUser(options?: RawAxiosRequestConfig) {
        return RequestControllerApiFp(this.configuration).getDataRequestsForRequestingUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the number of requests based on filters.
     * @summary Get the number of requests based on filters.
     * @param {Set<GetNumberOfRequestsDataTypeEnum>} [dataType] 
     * @param {string} [userId] 
     * @param {string} [emailAddress] 
     * @param {string} [adminComment] 
     * @param {Set<RequestStatus>} [requestStatus] 
     * @param {Set<AccessStatus>} [accessStatus] 
     * @param {Set<RequestPriority>} [requestPriority] 
     * @param {string} [reportingPeriod] 
     * @param {string} [datalandCompanyId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RequestControllerApi
     */
    public getNumberOfRequests(dataType?: Set<GetNumberOfRequestsDataTypeEnum>, userId?: string, emailAddress?: string, adminComment?: string, requestStatus?: Set<RequestStatus>, accessStatus?: Set<AccessStatus>, requestPriority?: Set<RequestPriority>, reportingPeriod?: string, datalandCompanyId?: string, options?: RawAxiosRequestConfig) {
        return RequestControllerApiFp(this.configuration).getNumberOfRequests(dataType, userId, emailAddress, adminComment, requestStatus, accessStatus, requestPriority, reportingPeriod, datalandCompanyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This head request checks whether the logged-in user has access to dataset.
     * @summary This head request checks whether the logged-in user has access to dataset.
     * @param {string} companyId 
     * @param {string} dataType 
     * @param {string} reportingPeriod 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RequestControllerApi
     */
    public hasAccessToDataset(companyId: string, dataType: string, reportingPeriod: string, userId: string, options?: RawAxiosRequestConfig) {
        return RequestControllerApiFp(this.configuration).hasAccessToDataset(companyId, dataType, reportingPeriod, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates a data request given the data request id.
     * @summary Updates a data request.
     * @param {string} dataRequestId 
     * @param {RequestStatus} [requestStatus] 
     * @param {AccessStatus} [accessStatus] 
     * @param {Set<string>} [contacts] 
     * @param {string} [message] 
     * @param {RequestPriority} [requestPriority] 
     * @param {string} [adminComment] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RequestControllerApi
     */
    public patchDataRequest(dataRequestId: string, requestStatus?: RequestStatus, accessStatus?: AccessStatus, contacts?: Set<string>, message?: string, requestPriority?: RequestPriority, adminComment?: string, options?: RawAxiosRequestConfig) {
        return RequestControllerApiFp(this.configuration).patchDataRequest(dataRequestId, requestStatus, accessStatus, contacts, message, requestPriority, adminComment, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * A bulk of data requests for specific frameworks and companies is being sent.
     * @summary Send a bulk request
     * @param {BulkDataRequest} bulkDataRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RequestControllerApi
     */
    public postBulkDataRequest(bulkDataRequest: BulkDataRequest, options?: RawAxiosRequestConfig) {
        return RequestControllerApiFp(this.configuration).postBulkDataRequest(bulkDataRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * A single of data requests for specific frameworks and companies is being sent.
     * @summary Send a single request
     * @param {SingleDataRequest} singleDataRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RequestControllerApi
     */
    public postSingleDataRequest(singleDataRequest: SingleDataRequest, options?: RawAxiosRequestConfig) {
        return RequestControllerApiFp(this.configuration).postSingleDataRequest(singleDataRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetAggregatedDataRequestsDataTypesEnum = {
    EutaxonomyFinancials: 'eutaxonomy-financials',
    EutaxonomyNonFinancials: 'eutaxonomy-non-financials',
    NuclearAndGas: 'nuclear-and-gas',
    Lksg: 'lksg',
    P2p: 'p2p',
    Sfdr: 'sfdr',
    Vsme: 'vsme',
    EsgDatenkatalog: 'esg-datenkatalog',
    Heimathafen: 'heimathafen',
    AdditionalCompanyInformation: 'additional-company-information'
} as const;
export type GetAggregatedDataRequestsDataTypesEnum = typeof GetAggregatedDataRequestsDataTypesEnum[keyof typeof GetAggregatedDataRequestsDataTypesEnum];
/**
 * @export
 */
export const GetDataRequestsDataTypeEnum = {
    EutaxonomyFinancials: 'eutaxonomy-financials',
    EutaxonomyNonFinancials: 'eutaxonomy-non-financials',
    NuclearAndGas: 'nuclear-and-gas',
    Lksg: 'lksg',
    P2p: 'p2p',
    Sfdr: 'sfdr',
    Vsme: 'vsme',
    EsgDatenkatalog: 'esg-datenkatalog',
    Heimathafen: 'heimathafen',
    AdditionalCompanyInformation: 'additional-company-information'
} as const;
export type GetDataRequestsDataTypeEnum = typeof GetDataRequestsDataTypeEnum[keyof typeof GetDataRequestsDataTypeEnum];
/**
 * @export
 */
export const GetNumberOfRequestsDataTypeEnum = {
    EutaxonomyFinancials: 'eutaxonomy-financials',
    EutaxonomyNonFinancials: 'eutaxonomy-non-financials',
    NuclearAndGas: 'nuclear-and-gas',
    Lksg: 'lksg',
    P2p: 'p2p',
    Sfdr: 'sfdr',
    Vsme: 'vsme',
    EsgDatenkatalog: 'esg-datenkatalog',
    Heimathafen: 'heimathafen',
    AdditionalCompanyInformation: 'additional-company-information'
} as const;
export type GetNumberOfRequestsDataTypeEnum = typeof GetNumberOfRequestsDataTypeEnum[keyof typeof GetNumberOfRequestsDataTypeEnum];
